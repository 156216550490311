@mixin opacity($opacity) {
    opacity: $opacity;
    // IE8 filter
    $opacity-ie: ($opacity * 100);
    filter: #{alpha(opacity=$opacity-ie)};
  }
  

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
            box-shadow: $shadow;
  }
  
@mixin transition-input-focus-color() {
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}


@mixin transition($time, $type){
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

@mixin transition-on-prop($prop ,$time, $type){
    -webkit-transition: $prop $time $type;
    -moz-transition: $prop $time $type;
    -o-transition: $prop $time $type;
    -ms-transition: all $time $type;
    transition: $prop $time $type;
}

@mixin rotate-180(){
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

@mixin transform-scale($value){
-webkit-transform: scale($value);
-moz-transform: scale($value);
-o-transform: scale($value);
-ms-transform: scale($value);
transform: scale($value);
}

@mixin transform-translate-x($value){
    -webkit-transform:  translate3d($value, 0, 0);
        -moz-transform: translate3d($value, 0, 0);
        -o-transform: translate3d($value, 0, 0);
        -ms-transform: translate3d($value, 0, 0);
        transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y-dropdown($value) {
    -webkit-transform:  translate3d(0,$value,0) !important;
        -moz-transform: translate3d(0,$value,0) !important;
        -o-transform: translate3d(0,$value,0) !important;
        -ms-transform: translate3d(0,$value,0) !important;
        transform: translate3d(0,$value,0) !important;
}

@mixin transform-translate-y($value){
    -webkit-transform:  translate3d(0,$value,0);
        -moz-transform: translate3d(0,$value,0);
        -o-transform: translate3d(0,$value,0);
        -ms-transform: translate3d(0,$value,0);
        transform: translate3d(0,$value,0);
}

@mixin bar-animation($type){
    -webkit-animation: $type 500ms linear 0s;
    -moz-animation: $type 500ms linear 0s;
    animation: $type 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@mixin topbar-x-rotation(){
    @keyframes topbar-x {
    0% {top: 0px; transform: rotate(0deg); }
    45% {top: 6px; transform: rotate(145deg); }
    75% {transform: rotate(130deg); }
    100% {transform: rotate(135deg); }
    }
    @-webkit-keyframes topbar-x {
    0% {top: 0px; -webkit-transform: rotate(0deg); }
    45% {top: 6px; -webkit-transform: rotate(145deg); }
    75% {-webkit-transform: rotate(130deg); }
    100% { -webkit-transform: rotate(135deg); }
    }
    @-moz-keyframes topbar-x {
    0% {top: 0px; -moz-transform: rotate(0deg); }
    45% {top: 6px; -moz-transform: rotate(145deg); }
    75% {-moz-transform: rotate(130deg); }
    100% { -moz-transform: rotate(135deg); }
    }
}

@mixin topbar-back-rotation(){
    @keyframes topbar-back {
    0% { top: 6px; transform: rotate(135deg); }
    45% { transform: rotate(-10deg); }
    75% { transform: rotate(5deg); }
    100% { top: 0px; transform: rotate(0); }
    }

    @-webkit-keyframes topbar-back {
    0% { top: 6px; -webkit-transform: rotate(135deg); }
    45% { -webkit-transform: rotate(-10deg); }
    75% { -webkit-transform: rotate(5deg); }
    100% { top: 0px; -webkit-transform: rotate(0); }
    }

    @-moz-keyframes topbar-back {
    0% { top: 6px; -moz-transform: rotate(135deg); }
    45% { -moz-transform: rotate(-10deg); }
    75% { -moz-transform: rotate(5deg); }
    100% { top: 0px; -moz-transform: rotate(0); }
    }
}

@mixin bottombar-x-rotation(){
    @keyframes bottombar-x {
    0% {bottom: 0px; transform: rotate(0deg);}
    45% {bottom: 6px; transform: rotate(-145deg);}
    75% {transform: rotate(-130deg);}
    100% {transform: rotate(-135deg);}
    }
    @-webkit-keyframes bottombar-x {
    0% {bottom: 0px; -webkit-transform: rotate(0deg);}
    45% {bottom: 6px; -webkit-transform: rotate(-145deg);}
    75% {-webkit-transform: rotate(-130deg);}
    100% {-webkit-transform: rotate(-135deg);}
    }
    @-moz-keyframes bottombar-x {
    0% {bottom: 0px; -moz-transform: rotate(0deg);}
    45% {bottom: 6px; -moz-transform: rotate(-145deg);}
    75% {-moz-transform: rotate(-130deg);}
    100% {-moz-transform: rotate(-135deg);}
    }
}

@mixin bottombar-back-rotation{
    @keyframes bottombar-back {
    0% { bottom: 6px;transform: rotate(-135deg);}
    45% { transform: rotate(10deg);}
    75% { transform: rotate(-5deg);}
    100% { bottom: 0px;transform: rotate(0);}
    }
    @-webkit-keyframes bottombar-back {
    0% {bottom: 6px;-webkit-transform: rotate(-135deg);}
    45% {-webkit-transform: rotate(10deg);}
    75% {-webkit-transform: rotate(-5deg);}
    100% {bottom: 0px;-webkit-transform: rotate(0);}
    }
    @-moz-keyframes bottombar-back {
    0% {bottom: 6px;-moz-transform: rotate(-135deg);}
    45% {-moz-transform: rotate(10deg);}
    75% {-moz-transform: rotate(-5deg);}
    100% {bottom: 0px;-moz-transform: rotate(0);}
    }

}


@mixin nc-rotate($degrees, $rotation) {
filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
-webkit-transform: rotate($degrees);
-moz-transform: rotate($degrees);
-ms-transform: rotate($degrees);
-o-transform: rotate($degrees);
transform: rotate($degrees);
}

@mixin nc-flip($horiz, $vert, $rotation) {
filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
-webkit-transform: scale($horiz, $vert);
-moz-transform: scale($horiz, $vert);
-ms-transform: scale($horiz, $vert);
-o-transform: scale($horiz, $vert);
transform: scale($horiz, $vert);
}
